/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
// @import "@ionic/angular/css/padding.css";
// @import "@ionic/angular/css/float-elements.css";
// @import "@ionic/angular/css/text-alignment.css";
// @import "@ionic/angular/css/text-transformation.css";
// @import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css'; /* For the snow theme */
@import '~quill/dist/quill.bubble.css'; /* Optional: For the bubble theme */

@import '@fontsource/geist-sans/index.css';

@font-face {
    font-family: 'Geist Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/geist-sans/geist-sans-latin-400-normal.woff2') format('woff2'),
         url('/assets/fonts/geist-sans/geist-sans-latin-400-normal.woff') format('woff');
}

//adding scroll-hidden class to hide scroll bar globally wherever the class is applied
.scroll-hidden::-webkit-scrollbar {
    display: none;
}


//TOAST STYLING
ion-toast.error-toast {
    --background: rgb(254, 226, 226);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(153, 27, 27);
}

ion-toast.warning-toast {
    --background: rgb(255, 237, 213);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(154, 52, 18);
}

ion-toast.success-toast {
    --background: rgb(220, 252, 231);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(22, 101, 52);
}

ion-toast.information-toast {
    --background: rgb(241, 245, 249);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(30, 41, 59);
}

ion-toast.move-reservation-or-remove-table-toast {
    &::part(button){
        --button-color: rgb(154, 52, 18);
    }
}


//SKELETON TEXT STYLING
ion-skeleton-text {
    --background-rgb: 46, 46, 63;
}
ion-skeleton-text.promoter-skeleton-text {
    --background-rgb: 189, 189, 196 !important;
}

.skeleton-item::part(native) {
    background-color: white !important;
}

.skeleton-list,
.skeleton-item,
.skeleton-label {
    background-color: white !important;
}


// Quill Styling
.quill-container {
    color: black; /* Ensure default text color is black */
    
    /* Optional: Adjust nested elements if necessary */
    h1, h2, h3, h4, h5, h6, p, ul, ol, li, span, strong, em, a, img, iframe {
      color: inherit; /* Inherit the color from the parent */
    }

    img {
      max-width: 100%;
      height: auto;
    }
  
    iframe {
      width: 100%;
      height: 400px; /* Adjust as needed */
    }
    


    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1.17em;
    }
    h4 {
        font-size: 1em;
    }
    h5 {
        font-size: 0.83em;
    }
    h6 {
        font-size: 0.67em;
    }


    blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
    }


    ol,
    ul {
        padding-left: 1.5em;
    }
    ol > li,
    ul > li {
        list-style-type: none;
    }
    ul > li::before {
        content: '\2022';
    }
    ul[data-checked=true],
    ul[data-checked=false] {
        pointer-events: none;
    }
    ul[data-checked=true] > li *,
    ul[data-checked=false] > li * {
        pointer-events: all;
    }
    ul[data-checked=true] > li::before,
    ul[data-checked=false] > li::before {
        color: #777;
        cursor: pointer;
        pointer-events: all;
    }
    ul[data-checked=true] > li::before {
        content: '\2611';
    }
    ul[data-checked=false] > li::before {
        content: '\2610';
    }
    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }
    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
    }
    ol li:before {
        content: counter(list-0, decimal) '. ';
    }
}
